import { Component, ElementRef, Renderer2, ViewChild,  } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

interface Image {
  thumbnail: string;
  src: string;
  no: number;
}

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})


export class DefaultComponent {

  @ViewChild('one') oneElement!: ElementRef;
  @ViewChild('two') twoElement!: ElementRef;
  @ViewChild('three') threeElement!: ElementRef;

  homeForm: FormGroup;
  isActiveTab = 1;
  activeId: any;

  public context: any;



  ngOnInit(): void {

  }


  images: Image[] = [
    { no: 5, thumbnail: 'assets/images/operation_awb.png', src: 'assets/images/operation_awb.png' },
    { no: 3, thumbnail: 'assets/images/operation_awb_list.png', src: 'assets/images/operation_awb_list.png' },
    { no: 2, thumbnail: 'assets/images/operation_drs.png', src: 'assets/images/operation_drs.png' },
    { no: 1, thumbnail: 'assets/images/operation_manifest.png', src: 'assets/images/operation_manifest.png' },
    { no: 4, thumbnail: 'assets/images/operation_pickups.png', src: 'assets/images/operation_pickups.png' }
    // Add more images as needed
  ];

  showLightbox = false;
  currentImage: Image = { thumbnail: '', src: '', no:0 };

  openLightbox(a): void {
    this.currentImage = this.images.filter(x => x.no == a)[0];
    this.showLightbox = true;
  }

  closeLightbox(): void {
    this.showLightbox = false;
  }


  handleClick(cardId: string) {
    const top = this.oneElement.nativeElement as HTMLElement;
    const middle = this.twoElement.nativeElement as HTMLElement;
    const bottom = this.threeElement.nativeElement as HTMLElement;

    if (cardId === 'one') {
      top.classList.add('go-time');
    } else if (cardId === 'two') {
      middle.classList.add('go-time');
    } else if (cardId === 'three') {
      bottom.classList.add('go-time');
    }

    setTimeout(() => {
      this.changeClasses(top, middle, bottom);
    }, 1000);

    setTimeout(() => {
      top.classList.remove('go-time');
      middle.classList.remove('go-time');
      bottom.classList.remove('go-time');
    }, 5000);
  }

  private changeClasses(top: HTMLElement, middle: HTMLElement, bottom: HTMLElement) {
    // Ensure only one card has the `top` class at a time
    const isTop = top.classList.contains('top');
    const isMiddle = middle.classList.contains('middle');
    const isBottom = bottom.classList.contains('bottom');

    // if (isTop) {
      top.classList.remove('top');
      top.classList.add('bottom');

      middle.classList.remove('middle');
      middle.classList.add('top');

      bottom.classList.remove('bottom');
      bottom.classList.add('middle');
    // } else if (isMiddle) {
    //   middle.classList.remove('middle');
    //   middle.classList.add('top');

    //   bottom.classList.remove('bottom');
    //   bottom.classList.add('middle');

    //   top.classList.remove('top');
    //   top.classList.add('bottom');
    // } else if (isBottom) {
    //   bottom.classList.remove('bottom');
    //   bottom.classList.add('middle');

    //   top.classList.remove('top');
    //   top.classList.add('bottom');

    //   middle.classList.remove('middle');
    //   middle.classList.add('top');
    // }
  }
  selectedIndex: number | null = null;

  setSelectedIndex(index: number) {
    this.selectedIndex = this.selectedIndex === index ? null : index;
  }
}
