import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SimplebarAngularModule } from 'simplebar-angular';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { UiModule } from '../shared/ui.module';

import { LayoutComponent } from 'src/app/components/layout/layout.component';
import { VerticalComponent } from 'src/app/components/layout/vertical/vertical.component';
import { TopbarComponent } from 'src/app/components/layout/topbar/topbar.component';
import { SidebarComponent } from 'src/app/components/layout/sidebar/sidebar.component';
import { RightsidebarComponent } from 'src/app/components/layout/rightsidebar/rightsidebar.component';
import { FooterComponent } from 'src/app/components/layout/footer/footer.component';
import { LanguageService } from 'src/app/services/langauge.service';
import { AppCoreService } from 'src/app/services/app-core.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    LayoutComponent,
    VerticalComponent,
    TopbarComponent,
    SidebarComponent,
    RightsidebarComponent,
    FooterComponent
    
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    UiModule,
    SimplebarAngularModule
  ],
  providers: [LanguageService]
})
export class LayoutModule { }
