import { Validators } from "@angular/forms";

export const TenantNameValidation = Validators.pattern('^[ A-Za-z0-9_@./#&+-]*$');
export const NameValidation = Validators.pattern(/^[a-zA-Z\s]+$/);
export const FloatFieldValidation = Validators.pattern(/^[0-9]+(\.[0-9]{1,3})?$/);
export const PositiveIntFieldValidation = Validators.pattern(/^[0-9]{1,3}$/);
export const AlphaNumericValidation = Validators.pattern(/^[a-zA-Z0-9]+$/);
export const ContactNumberValidation = Validators.pattern(/^[0-9]{10}$/);
export const WeightValidation = Validators.pattern(/^(?!0(?:\.0*)?$)\d{0,3}(?:\.\d{0,3})?$/);
export const VolumetricValidation = Validators.pattern(/^(?!0(?:\.0*)?$)\d{0,5}(?:\.\d{0,3})?$/);
export const RateValidation = Validators.pattern(/^(?!0(?:\.0*)?$)(?:\.\d{0,2})?$/);
export const WholeNumberValidation = Validators.pattern(/^(?!0)\d{1,3}$/);
// export const UrlValidation = Validators.pattern('^(?!https?:\/\/www\\.com$)(https?:\/\/)?(((www\\.(?!com$)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})|([a-zA-Z0-9.-]+)\\.com))(?<!www\.)(:\d+)?(/\\S*)?$');
export const UrlValidation = Validators.pattern('^(?!https?:\/\/www\\.com$)(https?:\/\/)?(www\\.)?([a-zA-Z0-9.-]+)\\.([a-zA-Z]{2,})(:\\d+)?(/\\S*)?$');
export const EmailValidation = Validators.pattern('^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$');
export const PortValidation = Validators.pattern('([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])');
export const PasswordValidation = Validators.pattern(/^.{5,20}$/);
export const GSTValidation = Validators.pattern('^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$');//GST breakup -(1,2)statecode (3,12)PANno. (13)EntityCode (14)blank (15)checkdigit
export const PANValidation = Validators.pattern(/^[A-Z]{5}\d{4}[A-Z]$/);//PAN -(1,5)alphabet (6,9)numbers (10)checkcode
export const REGNOValidation = Validators.pattern(/^.{5,20}$/);
export const CommentValidation = Validators.pattern(/^.{0,250}$/);
export const PincodeValidation = Validators.pattern(/^[0-9a-zA-Z]{6,10}$/);
export const AadhaarValidation = Validators.pattern('^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$');
export const PassportValidation = Validators.pattern('^[A-PR-WY-Z][1-9]\\d\\s?\\d{4}[1-9]$');//india passport validation
export const TanValidation = Validators.pattern('^[A-Z]{4}[0-9]{5}[A-Z]{1}');
export const FileSize = 1000000; //1 Mb File Size in Bytes

export const AmountValidation = Validators.pattern(/^(?!0(?:\.0*)?$)\d{0,12}(?:\.\d{0,3})?$/);
export const regexPattern = {
  nameField: /^[a-zA-Z\s]+$/,
  floatField: /^[0-9]+(\.[0-9]{1,3})?$/,
  positiveIntField: /^[0-9]{1,3}$/,
  alphaNumeric: /^[a-zA-Z0-9]+$/,
  telephone: /^[0-9]{10}$/,
  lbh: /^(?!0(?:\.0*)?$)\d{0,3}(?:\.\d{0,3})?$/,
  rate: /^(?!0(?:\.0*?)?$)\d*(\.\d{1,3})?$/,
  wholeNumbers: /^(?!0)\d{1,3}$/, //0 invalid,
  gstValidation: /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/,
  panValidation: /^[A-Z]{5}\d{4}[A-Z]$/,
  adhaarValidation: /^[0-9]{12}$/,
  generalDocValidation: /^[0-9a-zA-Z]{0,30}$/
};
