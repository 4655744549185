import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AppTokenService } from "./app-token.service";
import { HttpHandlerService } from "./http-handler.service";

@Injectable({
    providedIn: 'root'
})
export class GeoService {
    _httpHandler: HttpHandlerService;
    _apiUrl = environment.apiUrl;

    _getAllCountriesEndPoint = 'countries';
    _getAllStatesEndPoint = 'states';
    _getAllCitiesEndPoint = 'cities';
    _getAllPincodeDataEndPoint = 'pincodes'
    // _addEndPoint = 'products'
    // _updateEndPoint = 'products';
    // _updateStatus = 'products/status'
    // _getByIdEndPoint = 'products/';
    // _deleteEndPoint = 'products/';


    constructor(private httpHandler: HttpHandlerService, private appTokenService: AppTokenService) {
        this._httpHandler = httpHandler;
    }


    public GetAllCountries(params?: any): Observable<any> {
        const full_api_url = `${this._apiUrl}${this._getAllCountriesEndPoint}`
        const request_option = this._httpHandler.prepareRequest(
            'GET',
            full_api_url,
            {}
        );
        return this.httpHandler.request(request_option, this.appTokenService.tokenizedResponse);
    }

    public GetAllStates(params?: any): Observable<any> {
        const full_api_url = `${this._apiUrl}${this._getAllStatesEndPoint}?countrycode=${params.countrycode}`
        const request_option = this._httpHandler.prepareRequest(
            'GET',
            full_api_url,
            {}
        );
        return this.httpHandler.request(request_option, this.appTokenService.tokenizedResponse);
    }

    public GetAllCities(params?: any): Observable<any> {
        const full_api_url = `${this._apiUrl}${this._getAllCitiesEndPoint}?statecode=${params.statecode}`
        const request_option = this._httpHandler.prepareRequest(
            'GET',
            full_api_url,
            {}
        );
        return this.httpHandler.request(request_option, this.appTokenService.tokenizedResponse);
    }

    // public GetById(userId: any): Observable<any> {
    //     const full_api_url = `${this._apiUrl}${this._getByIdEndPoint}${userId}`;
    //     const request_options = this._httpHandler.prepareRequest(
    //         'GET',
    //         full_api_url,
    //         {}
    //     );
    //     return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
    // }


    // public Add(userData: any): Observable<any> {
    //     const full_api_url = `${this._apiUrl}${this._addEndPoint}`;
    //     const request_options = this._httpHandler.prepareRequest(
    //         'POST',
    //         full_api_url,
    //         userData
    //     );
    //     return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
    // }


    // public Update(userData: any): Observable<any> {
    //     const full_api_url = `${this._apiUrl}${this._updateEndPoint}`;
    //     const request_options = this._httpHandler.prepareRequest(
    //         'PUT',
    //         full_api_url,
    //         userData
    //     );
    //     return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
    // }


    // public UpdateStatus(status: boolean, productId: number): Observable<boolean> {
    //     const full_api_url = `${this._apiUrl}${this._updateStatus}`;
    //     const request_options = this._httpHandler.prepareRequest(
    //         'PUT',
    //         full_api_url,
    //         { status, productId }
    //     );
    //     return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
    // }


    // public Delete(userId: any): Observable<any> {
    //     const full_api_url = `${this._apiUrl}${this._deleteEndPoint}${userId}`;
    //     const request_option = this._httpHandler.prepareRequest('DELETE', full_api_url, {});
    //     return this._httpHandler.request(request_option, this.appTokenService.tokenizedResponse)
    // }

    public GetAllPincodes(params?: any): Observable<any> {
        const full_api_url = `${this._apiUrl}${this._getAllPincodeDataEndPoint}`
        const request_option = this._httpHandler.prepareRequest(
            'GET',
            full_api_url,
            {}
        );
        return this.httpHandler.request(request_option, this.appTokenService.tokenizedResponse);
    }
}



