import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from '../../components/default/default.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: DefaultComponent },
  { path: 'dashboard', loadChildren: () => import('../dashboard/dashboard.module').then(s => s.DashboardModule) },
  { path: 'settings', loadChildren: () => import('../settings/settings.module').then(s => s.SettingsModule) },
  { path: 'profile', loadChildren: () => import('../profile/profile.module').then(s => s.ProfileModule) },
  { path: 'setup', loadChildren: () => import('../setup/setup.module').then(s => s.SetupModule) },
  { path: 'network', loadChildren: () => import('../network/network.module').then(s => s.NetworkModule) },
  { path: 'config', loadChildren: () => import('../config/config.module').then(c => c.ConfigModule) },
  { path: 'operation', loadChildren: () => import('../operation/operation.module').then(o => o.OperationModule) },
  { path: 'iam', loadChildren: () => import('../user-management/user-management.module').then(u => u.UserManagementModule) },
  { path: 'reports', loadChildren: () => import('../report/report.module').then(r => r.ReportModule) },
  { path: 'invoices', loadChildren: () => import('../invoice/invoice.module').then(i => i.InvoiceModule) },
  { path: 'account', loadChildren: () => import('../account-receivable/account-receivable.module').then(r => r.AccountReceivableModule) },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
