import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppTokenService } from './app-token.service';
import { HttpHandlerService } from './http-handler.service';

@Injectable()
export class UserManagementService {
    _httpHandler: HttpHandlerService;
    _apiUrl = environment.apiUrl;

    _getRoleResourceEndPoint = 'roles';
    _getUserResourceEndPoint = 'users';

    constructor(private httpHandler: HttpHandlerService, private appTokenService: AppTokenService) {
        this._httpHandler = httpHandler;
    }

    //#region User Groups
    public GetAllUserGroups(): Observable<any> {
        const full_api_url = `${this._apiUrl}${this._getUserResourceEndPoint}/groups`;
        const request_options = this._httpHandler.prepareRequest(
            'GET',
            full_api_url,
            {}
        );
        return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
    }
    //#endregion

    //#region Roles
    public GetAllRoles(): Observable<any> {
        const full_api_url = `${this._apiUrl}${this._getRoleResourceEndPoint}`
        const request_option = this._httpHandler.prepareRequest(
            'GET',
            full_api_url,
            {}
        );
        return this.httpHandler.request(request_option, this.appTokenService.tokenizedResponse);
    }

    public GetRoleById(id: any): Observable<any> {
        const full_api_url = `${this._apiUrl}${this._getRoleResourceEndPoint}/${id}`;
        const request_options = this._httpHandler.prepareRequest(
            'GET',
            full_api_url,
            {}
        );
        return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
    }

    public AddRole(data: any): Observable<any> {
        const full_api_url = `${this._apiUrl}${this._getRoleResourceEndPoint}`;
        const request_options = this._httpHandler.prepareRequest(
            'POST',
            full_api_url,
            data
        );
        return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
    }

    public DeleteRole(id: any): Observable<any> {
        const full_api_url = `${this._apiUrl}${this._getRoleResourceEndPoint}/${id}`;
        const request_options = this._httpHandler.prepareRequest(
            'DELETE',
            full_api_url,
            {}
        );
        return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
    }

    public SaveRolePermissions(data: any): Observable<any> {
        const full_api_url = `${this._apiUrl}${this._getRoleResourceEndPoint}/permissions`;
        const request_options = this._httpHandler.prepareRequest(
            'POST',
            full_api_url,
            data
        );
        return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
    }
    //#endregion

    //#region Users

    /**
     * Get User List
     * @param Obj of Datatable
     * @returns Array of User
     */
    public GetAll(params?: any): Observable<any> {

        const full_api_url = `${this._apiUrl}${this._getUserResourceEndPoint}?sc=${params?.id}&pagelimit=${params?.length}&pageoffset=${params?.start}`;
        const request_options = this._httpHandler.prepareRequest(
            'GET',
            full_api_url,
            {}
        );
        return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
    }

    /**
     * Get User
     * @param Obj of Datatable
     * @returns User
     */
    public GetById(id: any): Observable<any> {

        const full_api_url = `${this._apiUrl}${this._getUserResourceEndPoint}/${id}`;
        const request_options = this._httpHandler.prepareRequest(
            'GET',
            full_api_url,
            {}
        );
        return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
    }

    /**
     * Get User
     * @param Obj of Datatable
     * @returns User
     */
    public GetUserById(id: any): Observable<any> {

        const full_api_url = `${this._apiUrl}${this._getUserResourceEndPoint}/${id}?type=user`;
        const request_options = this._httpHandler.prepareRequest(
            'GET',
            full_api_url,
            {}
        );
        return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
    }

    /**
     * Get User by type or user group
     * @param Obj of Datatable
     * @returns User
     */
    public GetUserByGroup(categoryType: string, sc?:number): Observable<any> {

        const full_api_url = `${this._apiUrl}${this._getUserResourceEndPoint}/group?type=${categoryType}&sc=${sc}`;
        const request_options = this._httpHandler.prepareRequest(
            'GET',
            full_api_url,
            {}
        );
        return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
    }

    /**
     * Add New User
     * @param user object
     * @returns observable object
     */
    public Add(data: any): Observable<any> {
        const full_api_url = `${this._apiUrl}${this._getUserResourceEndPoint}`;
        const request_options = this._httpHandler.prepareRequest(
            'POST',
            full_api_url,
            data
        );
        return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
    }

    /**
     * Update User
     * @param userData user input data
     * @returns res
     */
    public Update(data: any): Observable<any> {
        const full_api_url = `${this._apiUrl}${this._getUserResourceEndPoint}`;
        const request_options = this._httpHandler.prepareRequest(
            'PUT',
            full_api_url,
            data
        );
        return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
    }
    /***
     * Delete User By their id
     */
    public Delete(id: any): Observable<any> {
        const full_api_url = `${this._apiUrl}${this._getUserResourceEndPoint}/${id}`;
        const request_options = this._httpHandler.prepareRequest(
            'DELETE',
            full_api_url,
            {}
        );
        return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
    }

    public SaveUserPermissions(data: any): Observable<any> {
        const full_api_url = `${this._apiUrl}${this._getUserResourceEndPoint}/permissions`;
        const request_options = this._httpHandler.prepareRequest(
            'POST',
            full_api_url,
            data
        );
        return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
    }

    public DeleteUserPermissions(id: any): Observable<any> {
        const full_api_url = `${this._apiUrl}${this._getUserResourceEndPoint}/permissions/${id}`;
        const request_options = this._httpHandler.prepareRequest(
            'DELETE',
            full_api_url,
            {}
        );
        return this._httpHandler.request(request_options, this.appTokenService.tokenizedResponse);
    }

    //#endregion
}
