import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModule } from './ui.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbHighlight, NgbNavModule, NgbPaginationModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { PagetitleComponent } from '../../components/shared/pagetitle/pagetitle.component';
import { LoaderComponent } from '../../components/shared/loader/loader.component';
import { LocationComponent } from 'src/app/components/shared/location/location.component';
// import { PerformaComponent } from 'src/app/components/shared/performa-details/performadetails.component';
import { AgGridModule } from 'ag-grid-angular';
import { ForbiddenComponent } from 'src/app/components/forbidden/forbidden.component';
import { AccordionSharedComponent } from 'src/app/components/shared/accordion/accordion.component';

@NgModule({
  declarations: [
    LocationComponent,
    ForbiddenComponent,
    // PerformaComponent,
    AccordionSharedComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    FormsModule,
    ReactiveFormsModule,
    NgbHighlight,
    NgbDropdownModule,
    NgbTypeaheadModule,
    NgSelectModule,
    AgGridModule
  ],
  exports: [
    PagetitleComponent, LoaderComponent, LocationComponent, ForbiddenComponent, AccordionSharedComponent
    // PerformaComponent,
  ]
})

export class SharedModule { }
