import { Component, Input, Output, OnChanges, EventEmitter, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, FormGroupDirective, Validators } from "@angular/forms";
import { NgbTypeaheadConfig, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { GeoService } from 'src/app/services/geo.service';
import { MiscService } from 'src/app/services/misc/misc.service';
import { Observable, OperatorFunction, debounceTime, distinctUntilChanged, from, lastValueFrom, map, merge } from "rxjs";
import { ContactNumberValidation, PincodeValidation } from 'src/app/models/validator';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppCoreService } from 'src/app/services/app-core.service';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnChanges {
  @Input() formGroupName: string;
  @Input() formSubmitted: boolean = false;
  @Input() location: any;
  @Output() pincodeChild = new EventEmitter<void>();
  @Output() getLocation = new EventEmitter<any>();

  locationDetailsForm: FormGroup;

  _location: any[] = [];
  countryList: any[] = [];
  stateList: any[] = [];
  cityList: any[] = [];
  pincodeList: any[] = [];
  areaList = [];
  selectedLocation: any;
  countryCode: any;

  isCountryMandate = false;
  isStateMandate = false;
  isCityMandate = false;
  isAreaMandate = false;
  isAddressMandate = false;
  isMobileMandate = false;

  constructor(
    private rootFormGroup: FormGroupDirective,
    private geoService: GeoService,
    private miscService: MiscService,
    private typeaheadConfig: NgbTypeaheadConfig,
    private ngxLoader: NgxUiLoaderService,
    private appCoreService: AppCoreService,
  ) {
    // typeaheadConfig.showHint = true;
  }

  ngOnInit(): void {
    this.locationDetailsForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    this.bindCountries();
    this.bindAreas();
  }
  
  setPincodeValidation() {
    if(this.locationDetailsForm){
      if (typeof this.locationDetailsForm?.get('txtPincode')?.value === 'string') {
        this.locationDetailsForm.get('txtPincode').setValidators([PincodeValidation]);
      }
    }
  }

  async bindlocationByPincode(event: any) {
    try {
      this.locationDetailsForm?.get('txtPincode')?.clearValidators();
      if (event) {
        const pinObj = event.item;
        this.locationDetailsForm.get('txtPincode').clearValidators();
        this.locationDetailsForm.get('txtPincode').updateValueAndValidity();
        const foundPincode = this.pincodeList.filter((x: any) => pinObj.pincode == x.pincode);
        this.ngxLoader.start();
        if (foundPincode?.length > 0) {
          this.locationDetailsForm.get('ddlCountry').setValue(pinObj.countryisocode);
          // Used from to convert promises to observables before lastValueFrom
          await lastValueFrom(from(this.bindStatesByCountry(pinObj.countryisocode)));
          await lastValueFrom(from(this.bindCitiesByState(pinObj.stateisocode)));
          this.locationDetailsForm.get('ddlState').setValue(pinObj.stateisocode);
          this.locationDetailsForm.get('ddlCity').setValue(pinObj.cityisocode);
        }
        this.ngxLoader.stop();
        this.pincodeChild.emit();
      }
    } catch (error) {
      this.ngxLoader.stop();
      console.error(error);
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    this.locationDetailsForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    // changes.prop contains the old and the new value...
    this.setFieldValidations();
    if (changes['location'] && changes['location'].currentValue) {
      this.selectedLocation = changes['location'].currentValue;
      if (this.selectedLocation?.locationapply) {
        let country: any, state: any, city: any;
        const loc = this.selectedLocation.locationapply;
        country = this.countryList.filter((item) => item.code == loc.country)[0]?.isocode;

        this.bindStatesByCountry(country).then(() => {

          state = this.stateList.filter((item) => item.code == loc.state)[0]?.isocode;

          this.bindCitiesByState(state).then(() => {
            city = this.cityList.filter((item) => item.code == loc.city)[0]?.isocode;
            this.locationDetailsForm.patchValue({
              ddlCountry: country,
              ddlState: state,
              ddlCity: city
            })
          })
        });
      }

      let selectedLocArea = (typeof this.selectedLocation.area === 'string') ? { area: this.selectedLocation.area } : this.selectedLocation.area;
      let selectedLocPincode = (typeof this.selectedLocation.pincode === 'string') ? { pincode: this.selectedLocation.pincode } : this.selectedLocation.pincode;
      this.locationDetailsForm.patchValue({
        ddlCountry: this.selectedLocation.country,
        ddlState: this.selectedLocation.state,
        ddlCity: this.selectedLocation.city,
        txtPincode: selectedLocPincode,
        txtAddress1: this.selectedLocation.address1,
        txtAddress2: this.selectedLocation.address2,
        txtArea: selectedLocArea,
        txtMobileNo: this.selectedLocation.mobileno,
        txtTelephoneNo: this.selectedLocation.tel,
      });
    }
    // console.log('this.rootFormGroup, locationDetailsForm', this.rootFormGroup, this.locationDetailsForm);

    this.bindStatesByCountry(this.locationDetailsForm.get('ddlCountry').value);
    this.bindCitiesByState(this.locationDetailsForm.get('ddlState').value);
    this.bindPincodes();
  }

  setFieldValidation(controlName: string) {
    const control = this.locationDetailsForm.controls[controlName];
    const isMandate = control?.errors?.required ? true : false;
    if (isMandate) {
      switch (controlName) {
        case 'ddlCountry':
          this.isCountryMandate = isMandate;
          break;
        case 'ddlState':
          this.isStateMandate = isMandate;
          break;
        case 'ddlCity':
          this.isCityMandate = isMandate;
          break;
        case 'txtArea':
          this.isAreaMandate = isMandate;
          break;
        case 'txtAddress1':
          this.isAddressMandate = isMandate;
          break;
        case 'txtMobileNo':
          this.isMobileMandate = isMandate;
          break;
        default:
          this.isCountryMandate = true;
          this.isStateMandate = true;
          this.isCityMandate = true;
          this.isAreaMandate = true;
          this.isAddressMandate = true;
          this.isMobileMandate = true;
          break;
      }
      this.locationDetailsForm.get(controlName).setValidators([Validators.required]);
    } else {
      this.locationDetailsForm.get(controlName).clearValidators();
    }

    this.locationDetailsForm.get(controlName).updateValueAndValidity();
  }

  setFieldValidations() {
    this.setPincodeValidation();
    this.setFieldValidation('ddlCountry');
    this.setFieldValidation('ddlState');
    this.setFieldValidation('ddlCity');
    this.setFieldValidation('txtArea');
    this.setFieldValidation('txtAddress1');
    this.setFieldValidation('txtMobileNo');
    //set mobile validation - issue formControl validation parent-to-child
    this.locationDetailsForm.get('txtMobileNo').addValidators([ContactNumberValidation]);
    this.locationDetailsForm.get('txtMobileNo').updateValueAndValidity();
  }


  bindCountries() {
    try {
      this.geoService.GetAllCountries().subscribe((res) => {
        if (res && res.success) {
          const metaInfo = this.appCoreService.GetUserData()?.metaInfo;
          const defaultCountryCode = metaInfo.defaultcountry;
          if (metaInfo.operationtype.includes('Domestic') && metaInfo.operationtype.includes('International')) {
            this.countryList = res.data;
          } else if (metaInfo.operationtype.includes('Domestic')) {
            this.countryList = res.data.filter(item => item.isocode === defaultCountryCode);
            this.locationDetailsForm.get('ddlCountry').setValue(defaultCountryCode);
            this.locationDetailsForm.get('ddlCountry').disable();
            this.onCountryChange();
          } else if (metaInfo.operationtype.includes('International')) {
            this.countryList = res.data.filter(item => item.isocode !== defaultCountryCode);
          } else {
            this.countryList = res.data;
          }
          
          if (this._location.length)
            this._location[0]["country"] = this.countryList;
          else
            this._location.push({ "country": this.countryList });

          this.getLocation.emit(this._location);
        }
      });
    } catch (e) {
      console.error(e);
    }
  }

  onCountryChange() {
    const selectedValue = this.locationDetailsForm.get('ddlCountry').value;
    this.locationDetailsForm.get('ddlState').reset();
    this.locationDetailsForm.get('ddlCity').reset();
    this.stateList = [];
    this.cityList = [];
    if (selectedValue) {
      this.bindStatesByCountry(selectedValue);
    }
  }

  async bindStatesByCountry(countryCode?: string): Promise<void> {
    try {
      if (countryCode?.length) {

        const observable = this.geoService.GetAllStates({ countrycode: countryCode });
        const res = await lastValueFrom(observable);

        if (res && res.success) {
          this.stateList = res.data;
          this.countryCode = countryCode;

          //this._location.push({"state": this.stateList});

          if (this._location.length)
            this._location[0].state = this.stateList;
          else
            this._location.push({ "state": this.stateList });

          this.getLocation.emit(this._location);
        } else {
          throw new Error('Error fetching states');
        }
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  onStateChange() {
    const selectedValue = this.locationDetailsForm.get('ddlState').value;
    this.locationDetailsForm.get('ddlCity').reset();
    this.cityList = [];
    if (selectedValue) {
      this.bindCitiesByState(selectedValue);
    }
  }

  async bindCitiesByState(stateCode?: string): Promise<void> {
    try {
      if (stateCode?.length) {
        const observable = this.geoService.GetAllCities({ statecode: stateCode });
        const res = await lastValueFrom(observable);

        if (res && res.success) {
          this.cityList = res.data;

          //this._location.push({"city": this.cityList});
          if (this._location.length)
            this._location[0].city = this.cityList;
          else
            this._location.push({ "city": this.cityList });

          this.getLocation.emit(this._location);
        } else {
          throw new Error('Error fetching cities');
        }
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  bindAreas() {
    try {
      this.miscService.GetAllAreas().subscribe((res) => {
        // console.log('areas', res);
        if (res && res.success) {
          this.areaList = res.data;
          // console.log(this.locationDetailsForm.get('txtArea').value);
        } else {
          // handle some error
        }
      });
    } catch (e) {
      console.error(e);
    }
  }

  bindPincodes() {
    try {
      this.geoService.GetAllPincodes().subscribe((res) => {
        // console.log('pincode data', res);
        if (res && res.success) {
          this.pincodeList = res.data;
          // console.log(this.locationDetailsForm.get('txtArea').value);
        } else {
          // handle some error
        }
      });
    } catch (e) {
      console.error(e);
    }
  }

  searchArea: OperatorFunction<string, readonly { area; flag }[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term === ''
          ? []
          : this.areaList.filter((v) => v.area.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10),
      ),
    );

  // search = (text$: Observable<string>) => {
  //   const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
  //   return merge(debouncedText$).pipe(
  //     map(term => (term === '' ? this.areaList
  //       : this.areaList.filter(v => v.area.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
  //   );
  // }

  areaFormatter = (x: { area: string }) => x.area;

  searchPincode: OperatorFunction<string, readonly { pincode; flag }[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term === ''
          ? []
          : this.pincodeList.filter((v) => v.pincode.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10),
      ),
    );

  pincodeFormatter = (x: { pincode: string }) => x.pincode;

}
