import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';

@Injectable()
export class Constants {

  FILE_SIZE=4194304;
  IMAGE_PREFIX = environment.imagePrefix;
  public GetTitleForUrl(value: string): string {
      return value.replace(/\s/g , '-').toLowerCase();
  }

  public GetDropDownSettings(ddText, ddSearchBy, ddLazyLoading = true): any {
    return {
      singleSelection: false,
      text: ddText || '-- Select --',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: true,
      searchBy: ddSearchBy || ['name'],
      lazyLoading: ddLazyLoading,
      maxHeight: 130,
      minHeight: 30,
      classes: 'myclass custom-class'
    };
  }

  public GetMenuSettings(): any {
    return {
      CustomerManagement: true,
      // UserManagement: true,
      // Roles: true,
      // Users: true
    };
  }
}
