<form autocomplete="off" [formGroup]="locationDetailsForm">
    <div class="row">
        <div class="col-md-12">
            <div class="">
                <!-- <div class="card-header bg-transparent border-bottom text-uppercase"> Location Details </div> -->
                <!-- <div class="card-body">
                    
                </div> -->
                <div class="row">
                    <div class="col-md-3">
                        <div class="mb-2">
                            <label for="pincode" class="form-label">Pincode</label>
                            <div class="input-group">
                                <input formControlName="txtPincode" type="text" class="form-control col form-control-sm"
                                    placeholder="Type to search" [ngbTypeahead]="searchPincode" [resultTemplate]="pt"
                                    [inputFormatter]="pincodeFormatter" (selectItem)="bindlocationByPincode($event)" (keyup)="setPincodeValidation()"
                                    [ngClass]="{'is-invalid': (formSubmitted || locationDetailsForm.get('txtPincode')?.touched) && locationDetailsForm.get('txtPincode').errors}" />
                                <div *ngIf="(formSubmitted || locationDetailsForm.get('txtPincode')?.touched) && locationDetailsForm.get('txtPincode').errors"
                                    class="invalid-feedback">
                                    <span *ngIf="locationDetailsForm.get('txtPincode').errors.pattern">Min 6 and Max 10 characters allowed.</span>
                                </div>
                            </div>
                            <ng-template #pt let-p="result" let-t="term">
                                <ngb-highlight [result]="p.pincode" [term]="t"></ngb-highlight>
                                <!-- <span> | {{r['code']}}</span> -->
                            </ng-template>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="mb-2">
                            <label for="country" class="form-label">Country <span *ngIf="isCountryMandate"
                                    class="mandatory">*</span></label>
                            <ng-select id="country" placeholder="Select" [appendTo]="'body'"
                                (change)="onCountryChange()" [items]="countryList" bindLabel="name"
                                [bindValue]="'isocode'" formControlName="ddlCountry"
                                [ngClass]="{'is-invalid': (formSubmitted || locationDetailsForm.get('ddlCountry')?.touched) && locationDetailsForm.get('ddlCountry').errors}">

                            </ng-select>
                            <div *ngIf="(formSubmitted || locationDetailsForm.get('ddlCountry')?.touched) && locationDetailsForm.get('ddlCountry').errors"
                                class="invalid-feedback">
                                <span *ngIf="locationDetailsForm.get('ddlCountry').errors.required">Country is
                                    required</span>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="mb-2">
                            <label for="state" class="form-label">State<span *ngIf="isStateMandate"
                                    class="mandatory">*</span></label>
                            <ng-select id="state" placeholder="Select" [appendTo]="'body'" (change)="onStateChange()"
                                [items]="stateList" bindLabel="name" [bindValue]="'isocode'" formControlName="ddlState"
                                [ngClass]="{'is-invalid': (formSubmitted || locationDetailsForm.get('ddlState')?.touched) && locationDetailsForm.get('ddlState').errors}">
                            </ng-select>
                            <div *ngIf="(formSubmitted || locationDetailsForm.get('ddlState')?.touched) && locationDetailsForm.get('ddlState').errors"
                                class="invalid-feedback">
                                <span *ngIf="locationDetailsForm.get('ddlState').errors.required">State is
                                    required</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="mb-2">
                            <label for="city" class="form-label">City<span *ngIf="isCityMandate"
                                    class="mandatory">*</span></label>
                            <ng-select id="city" placeholder="Select" [appendTo]="'body'" [items]="cityList"
                                bindLabel="name" [bindValue]="'isocode'" formControlName="ddlCity"
                                [ngClass]="{'is-invalid': (formSubmitted || locationDetailsForm.get('ddlCity')?.touched) && locationDetailsForm.get('ddlCity').errors}">
                            </ng-select>
                            <div *ngIf="(formSubmitted || locationDetailsForm.get('ddlCity')?.touched) && locationDetailsForm.get('ddlCity').errors"
                                class="invalid-feedback">
                                <span *ngIf="locationDetailsForm.get('ddlCity').errors.required">City is required</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-2">
                            <label for="address1" class="form-label">Address Line 1 <span *ngIf="isAddressMandate"
                                    class="mandatory">*</span></label>
                            <input type="text" class="form-control form-control-sm" id="address1"
                                formControlName="txtAddress1" maxlength="60"
                                [ngClass]="{'is-invalid': (formSubmitted || locationDetailsForm.get('txtAddress1')?.touched) && locationDetailsForm.get('txtAddress1').errors}">
                            <div *ngIf="(formSubmitted || locationDetailsForm.get('txtAddress1')?.touched) && locationDetailsForm.get('txtAddress1').errors"
                                class="invalid-feedback">
                                <span *ngIf="locationDetailsForm.get('txtAddress1').errors.required">Address1 is
                                    required</span>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-2">
                            <label for="address2" class="form-label">Address Line 2 </label>
                            <input type="text" class="form-control form-control-sm" id="address2"
                                formControlName="txtAddress2" maxlength="60">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                        <div class="mb-2">
                            <label for="area" class="form-label">Area<span *ngIf="isAreaMandate"
                                    class="mandatory">*</span> </label>
                            <!-- <input type="text"  placeholder="" maxlength="30" class="form-control form-control-sm" id="area"
                                formControlName="txtArea" [ngClass]="{'is-invalid': (formSubmitted || locationDetailsForm.get('txtArea')?.touched) && locationDetailsForm.get('txtArea').errors}">
                             -->
                            <div class="input-group">
                                <input formControlName="txtArea" type="text" class="form-control col form-control-sm"
                                    placeholder="Type to search" [ngbTypeahead]="searchArea" [resultTemplate]="rt"
                                    [inputFormatter]="areaFormatter" maxlength="30"
                                    [ngClass]="{'is-invalid': (formSubmitted || locationDetailsForm.get('txtArea')?.touched) && locationDetailsForm.get('txtArea').errors}" />
                                <!-- <div class="input-group-text group-label-sm">
                                    <i class="fas fa-search"></i>
                                </div> -->
                                <div *ngIf="(formSubmitted || locationDetailsForm.get('txtArea')?.touched) && locationDetailsForm.get('txtArea').errors"
                                    class="invalid-feedback">
                                    <span *ngIf="locationDetailsForm.get('txtArea').errors.required">Area is
                                        required</span>
                                    <span *ngIf="locationDetailsForm.get('txtArea').errors.maxlength">Maximum of 30
                                        characters.</span>
                                </div>
                            </div>
                            <ng-template #rt let-r="result" let-t="term">
                                <ngb-highlight [result]="r.area" [term]="t"></ngb-highlight>
                                <!-- <span> | {{r['code']}}</span> -->
                            </ng-template>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="mb-2">
                            <label for="mobileno" class="form-label">Mobile No.<span *ngIf="isMobileMandate"
                                    class="mandatory">*</span>
                            </label>
                            <input type="number" placeholder="Ex. 0123456789"
                                class="form-control form-control-sm blurred-placeholder" id="mobileno"
                                formControlName="txtMobileNo"
                                [ngClass]="{'is-invalid': (formSubmitted || locationDetailsForm.get('txtMobileNo')?.touched) && locationDetailsForm.get('txtMobileNo').errors}">
                            <div *ngIf="(formSubmitted || locationDetailsForm.get('txtMobileNo')?.touched) && locationDetailsForm.get('txtMobileNo').errors"
                                class="invalid-feedback">
                                <span *ngIf="locationDetailsForm.get('txtMobileNo').errors.required">Mobile No. is
                                    required</span>
                                <span *ngIf="locationDetailsForm.get('txtMobileNo').errors.pattern">Must be of 10
                                    digits.</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="mb-2">
                            <label for="telephoneNo" class="form-label">Telephone No.</label>
                            <input type="number" placeholder="Ex. 0123456789" class="form-control form-control-sm"
                                id="telephoneNo" formControlName="txtTelephoneNo"
                                [ngClass]="{'is-invalid': (formSubmitted || locationDetailsForm.get('txtTelephoneNo')?.touched) && locationDetailsForm.get('txtTelephoneNo').errors}">
                            <div *ngIf="(formSubmitted || locationDetailsForm.get('txtTelephoneNo')?.touched) && locationDetailsForm.get('txtTelephoneNo').errors"
                                class="invalid-feedback">
                                <span *ngIf="locationDetailsForm.get('txtTelephoneNo').errors.pattern">Must be of 10
                                    digits.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</form>
