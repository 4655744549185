import { Injectable } from "@angular/core";
import { Observable, catchError } from "rxjs";
import { environment } from "src/environments/environment";
import { AppTokenService } from "../app-token.service";
import { HttpHandlerService } from "../http-handler.service";

@Injectable({
    providedIn: 'root'
})
export class MiscService {

    _httpHandler: HttpHandlerService;
    _apiUrl = environment.apiUrl;
    _getAllIndustriesEndPoint = 'industries';
    _getAllAreasEndPoint = 'areas';
    _getAllInvoiceTermsEndPoint = 'invoiceterms';
    _getAllInvoiceFormatsEndPoint = 'invoiceformats';
    _getAllPaymentModesEndPoint = 'paymentmodes';
    _flightsEndPoint = 'flights';
    _getGoodsEndPoint = 'goods';
    _getCalculationBaseEndPoint = 'calculationbase';
    _getAllBanksEndPoint = 'banks';
    _getGlobalConfigEndPoint = 'globalconfig';

    constructor(private httpHandler: HttpHandlerService, private appTokenService: AppTokenService) {
        this._httpHandler = httpHandler;
    }

    public GetAllIndustries(params?: any): Observable<any> {
        const full_api_url = `${this._apiUrl}${this._getAllIndustriesEndPoint}`
        const request_option = this._httpHandler.prepareRequest(
            'GET',
            full_api_url,
            {}
        );
        return this.httpHandler.request(request_option, this.appTokenService.tokenizedResponse);
    }

    public GetAllAreas(params?: any): Observable<any> {
        const full_api_url = `${this._apiUrl}${this._getAllAreasEndPoint}`
        const request_option = this._httpHandler.prepareRequest(
            'GET',
            full_api_url,
            {}
        );
        return this.httpHandler.request(request_option, this.appTokenService.tokenizedResponse);
    }

    public GetAllInvoiceTerms(params?: any): Observable<any> {
        const full_api_url = `${this._apiUrl}${this._getAllInvoiceTermsEndPoint}`
        const request_option = this._httpHandler.prepareRequest(
            'GET',
            full_api_url,
            {}
        );
        return this.httpHandler.request(request_option, this.appTokenService.tokenizedResponse);
    }

    public GetAllInvoiceFormats(params?: any): Observable<any> {
        const full_api_url = `${this._apiUrl}${this._getAllInvoiceFormatsEndPoint}`
        const request_option = this._httpHandler.prepareRequest(
            'GET',
            full_api_url,
            {}
        );
        return this.httpHandler.request(request_option, this.appTokenService.tokenizedResponse);
    }

    public GetAllPaymentModes(): Observable<any> {
        const full_api_url = `${this._apiUrl}${this._getAllPaymentModesEndPoint}`
        const request_option = this._httpHandler.prepareRequest(
            'GET',
            full_api_url,
            {}
        );
        return this.httpHandler.request(request_option, this.appTokenService.tokenizedResponse);
    }

    public GetAllFlights(): Observable<any> {
        const full_api_url = `${this._apiUrl}${this._flightsEndPoint}`
        const request_option = this._httpHandler.prepareRequest(
            'GET',
            full_api_url,
            {}
        );
        return this.httpHandler.request(request_option, this.appTokenService.tokenizedResponse);
    }

    //Goods
    public GetAllGoods(params?: any): Observable<any> {
        const full_api_url = `${this._apiUrl}${this._getGoodsEndPoint}`
        const request_option = this._httpHandler.prepareRequest(
            'GET',
            full_api_url,
            {}
        );
        return this.httpHandler.request(request_option, this.appTokenService.tokenizedResponse)
    }

    public GetAllCalculationBase(params?: any): Observable<any> {
        const full_api_url = `${this._apiUrl}${this._getCalculationBaseEndPoint}`;
        const request_option = this._httpHandler.prepareRequest(
            'GET',
            full_api_url,
            {}
        );
        return this.httpHandler.request(request_option, this.appTokenService.tokenizedResponse);
    }

    public GetMasterConfigs(category: string): Observable<any> {
        const full_api_url = `${this._apiUrl}${this._getGlobalConfigEndPoint}?category=${category}`
        const request_option = this._httpHandler.prepareRequest(
            'GET',
            full_api_url,
            {}
        );
        return this.httpHandler.request(request_option, this.appTokenService.tokenizedResponse);
    }



    public GetAllBanks(params?: any): Observable<any> {
        const full_api_url = `${this._apiUrl}${this._getAllBanksEndPoint}`
        const request_option = this._httpHandler.prepareRequest(
            'GET',
            full_api_url,
            {}
        );
        return this.httpHandler.request(request_option, this.appTokenService.tokenizedResponse);
    }
}