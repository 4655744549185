import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppCoreService } from 'src/app/services/app-core.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  _router: Router;

  constructor(
      private appCoreService: AppCoreService,
      private router: Router
    ) {
      this._router = router;
   }

  ngOnInit(): void {

  }

}
