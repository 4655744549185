import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-accordion',
  template: `
    <div class="accordion tran">
      <div class="card" *ngFor="let state of panelOpenStates; let i = index">
        <div class="card-header collapsed" (click)="togglePanel(i)" [attr.aria-expanded]="state ? 'true' : 'false'">
          <a class="card-title">{{ title }}</a>
        </div>
        <div class="card-body collapse" [ngClass]="{'show': state }">
          <ng-content select="[content]"></ng-content>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['../../setup/customers/event-config/event-config.component.scss']
})
export class AccordionSharedComponent implements OnInit, OnChanges {
  @Input() title = ''; // Default value 'heading1'
  @Input() content = ''; // Default value is an empty string
  @Input() isAllExpanded: boolean = false;
  panelOpenStates: boolean[] = [false]; // Open state for each panel

  ngOnInit(): void {
    this.togglePanel(this.isAllExpanded ? 0 : -1);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('isAllExpanded' in changes && this.isAllExpanded) {
      this.expandAll();
    }else{
      this.collapseAll();
    }
  }
  
  togglePanel(index?: number): void {
    this.panelOpenStates[index] = !this.panelOpenStates[index];
  }

  expandAll(): void {
    this.panelOpenStates = this.panelOpenStates.map(() => true);
  }

  collapseAll(): void {
    this.panelOpenStates = this.panelOpenStates.map(() => false);
  }
}
