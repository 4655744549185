
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbAccordion, NgbAccordionToggle, NgbDropdownModule, NgbPanel, NgbPanelContent, NgbPanelTitle, NgbNavModule} from '@ng-bootstrap/ng-bootstrap';
import { HomeRoutingModule } from './home-routing.module';
import { NetworkModule } from '../network/network.module';
import { HomeComponent } from './../../components/home/home.component';
import { UiModule } from '../shared/ui.module';
import { SharedModule } from '../shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { AgGridModule } from 'ag-grid-angular';
import { ExcelService } from 'src/app/services/excel.service';
import { AppCoreService } from 'src/app/services/app-core.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UserManagementService } from 'src/app/services/user-management.service';
import { DefaultComponent } from 'src/app/components/default/default.component';


@NgModule({
  declarations: [
    HomeComponent,
    DefaultComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    HomeRoutingModule,
    // UiModule,
    SharedModule,
    NgbAccordion,
    NgbPanel,
    NgSelectModule,
    AgGridModule,
    NgbPanelContent,
    NgbPanelTitle,
    NgbNavModule
  ],
  exports: [HomeComponent, DefaultComponent],
  providers: [ExcelService, UserManagementService]
})
export class HomeModule { }
